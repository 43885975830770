const MANAGEMENT = "MANAGEMENT";

export const INIT = `${MANAGEMENT}/INIT`;
export const INIT_STATE = `${MANAGEMENT}/INIT_STATE`;
export const SET_RECEIPT_LIST = `${MANAGEMENT}/SET_RECEIPT_LIST`;
export const GET_RECEIPT_LIST = `${MANAGEMENT}/GET_RECEIPT_LIST`;
export const SET_IS_LOADING = `${MANAGEMENT}/SET_IS_LOADING`;
export const SET_TOTAL = `${MANAGEMENT}/SET_TOTAL`;
export const SET_PAGE = `${MANAGEMENT}/SET_PAGE`;
export const SET_PAGE_SIZE = `${MANAGEMENT}/SET_PAGE_SIZE`;
export const SET_QUERY = `${MANAGEMENT}/SET_QUERY`;
export const SUBMIT_EDIT_RECEIPT = `${MANAGEMENT}/SUBMIT_EDIT_RECEIPT`;
export const SUBMIT_DELETE_RECEIPT = `${MANAGEMENT}/SUBMIT_DELETE_RECEIPT`;

export const init = () => ({
  type: INIT,
});

export const initState = () => ({
  type: INIT_STATE,
});

export const setReceiptList = (list) => ({
  type: SET_RECEIPT_LIST,
  payload: list,
});

export const getReceiptList = () => ({
  type: GET_RECEIPT_LIST,
});

export const setIsLoading = (loading) => ({
  type: SET_IS_LOADING,
  payload: loading,
});

export const setTotal = (total) => ({
  type: SET_TOTAL,
  payload: total,
});

export const setPage = (page) => ({
  type: SET_PAGE,
  payload: page,
});

export const setQuery = (query) => ({
  type: SET_QUERY,
  payload: query,
});

export const setPageSize = (pageSize) => ({
  type: SET_PAGE_SIZE,
  payload: pageSize,
});

export const submitEditReceipt = (values) => ({
  type: SUBMIT_EDIT_RECEIPT,
  payload: values,
});

export const submitDeleteReceipt = (values) => ({
  type: SUBMIT_DELETE_RECEIPT,
  payload: values,
});
