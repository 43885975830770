import { put, take } from 'redux-saga/effects';
import { INIT_APP, setMenuCollap } from './appAction';

function* init() {
  yield take(INIT_APP);
  yield put(setMenuCollap(false));
}

export default function* appSaga() {
  yield init();
}
