import {
  INIT_STATE,
  SET_SUBMITTING,
  SET_SUBMITTED_ID,
  SET_OPEN_PDF_VIWER,
} from './homeActions';

const initialState = {
  isSubmiting: false,
  submittedId: 0,
  openPdfViewer: false,
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_STATE:
      return initialState;
    case SET_SUBMITTING:
      return {
        ...state,
        isSubmiting: action.payload,
      };
    case SET_SUBMITTED_ID:
      return {
        ...state,
        submittedId: action.payload,
      };
    case SET_OPEN_PDF_VIWER:
      return {
        ...state,
        openPdfViewer: action.payload,
      };
    default:
      return state;
  }
};

export default homeReducer;
