import React, { useCallback } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';

const FormikInput = (props) => {
  const {
    label,
    name,
    id,
    type,
    placeholder,
    divClassName,
    maxLength,
    onValueChange,
    labelClassName,
    disabled,
  } = props;

  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);

  const handleChange = useCallback(
    (e) => {
      helpers.setValue(e.target.value);
      if (onValueChange) {
        onValueChange(e.target.value);
      }
    },
    [helpers, onValueChange]
  );

  return (
    <div className={`d-flex flex-column ${divClassName ? divClassName : ''}`}>
      {label && (
        <div>
          <label
            htmlFor={props.id || props.name}
            className={`${labelClassName || ''}`}
          >
            {label}
          </label>
        </div>
      )}
      <div>
        <input
          type={type}
          name={name}
          id={id}
          maxLength={maxLength}
          className="form-control"
          placeholder={placeholder}
          onChange={handleChange}
          value={field.value || ''}
          disabled={disabled}
          style={{ border: 1, borderStyle: 'solid', borderColor: 'grey' }}
        />
      </div>
      {meta.touched && meta.error ? (
        <div className="error mt-1">{meta.error}</div>
      ) : null}
    </div>
  );
};

FormikInput.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.string.isRequired,
  ]),
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onValueChange: PropTypes.func,
  labelClassName: PropTypes.string,
  divClassName: PropTypes.string,
};

export default FormikInput;
