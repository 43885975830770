import { i18n } from "./i18n/i18n";

export const translateEnum = (en) =>
  Object.keys(en).map((e) => ({
    label: i18n.t(en[e].label),
    value: en[e].value,
  }));

export const getEnumLabel = (en, value) => {
  let val = value;

  Object.keys(en).forEach((e) => {
    if (en[e].value === value) {
      val = i18n.t(en[e].label);
    }
  });

  return val;
};

export const getEnumColorCode = (en, value) => {
  let val = value;

  Object.keys(en).forEach((e) => {
    if (en[e].value === value) {
      val = en[e].colorCode;
    }
  });

  return val;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  translateEnum,
  getEnumLabel,
  getEnumColorCode,
};
