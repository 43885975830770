import Select from 'react-select';

const MultiSelect = ({
  field,
  form,
  label,
  options,
  isMulti,
  divClassName,
  placeholder,
}) => {
  const onChange = (option) => {
    form.setFieldValue(
      field.name,
      option ? option.map((item) => item.value) : []
    );
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option) => field.value.indexOf(option.value) >= 0)
        : options.find((option) => option.value === field.value);
    } else {
      return isMulti ? [] : '';
    }
  };

  if (!isMulti) {
    return (
      <div className={`${divClassName || ''}`}>
        {label && (
          <label className="formlabel" htmlFor={field.name}>
            {label}
          </label>
        )}
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          options={options}
          name={field.name}
          value={getValue() ? getValue() : ''}
          onChange={(option) => form.setFieldValue(field.name, option.value)}
          placeholder={placeholder}
        />
        {form.errors && form.errors[field.name] ? (
          <div className="error mt-1">{form.errors[field.name]}</div>
        ) : null}
      </div>
    );
  } else {
    return (
      <div className={`${divClassName || ''}`}>
        {label && (
          <label className="formlabel" htmlFor={field.name}>
            {label}
          </label>
        )}
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          name={field.name}
          value={getValue()}
          onChange={onChange}
          options={options}
          isMulti={true}
          placeholder={placeholder}
        />
        {form.errors && form.errors[field.name] ? (
          <div className="error mt-1">{form.errors[field.name]}</div>
        ) : null}
      </div>
    );
  }
};

export default MultiSelect;
