const APP = 'CONTEXT/APP';

export const INIT_APP = `${APP}/INIT_APP`;
export const SET_MENU_COLLAP = `${APP}/SET_MENU_COLLAP`;

export const initApp = () => ({
  type: INIT_APP,
});

export const setMenuCollap = (menu) => ({
  type: SET_MENU_COLLAP,
  payload: menu,
});
