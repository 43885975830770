import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enLocalization from './locales/en.json';

const resources = {
  en: { translation: enLocalization },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  debug: false,
});

class Localization extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <I18nextProvider i18n={i18n}>
        {children}
      </I18nextProvider>
    );
  }
}

Localization.propTypes = {
  children: PropTypes.any.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Localization);

export {
  i18n,
};

