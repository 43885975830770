const HOME = 'HOME';

export const INIT = `${HOME}/INIT`;
export const INIT_STATE = `${HOME}/INIT_STATE`;
export const SUBMIT_RECEIPT = `${HOME}/SUBMIT_RECEIPT`;
export const SET_SUBMITTING = `${HOME}/SET_SUBMITTING`;
export const SET_SUBMITTED_ID = `${HOME}/SET_SUBMITTED_ID`;
export const SET_OPEN_PDF_VIWER = `${HOME}/SET_OPEN_PDF_VIWER`;

export const init = () => ({
  type: INIT,
});

export const initState = () => ({
  type: INIT_STATE,
});

export const submitReceipt = (details) => ({
  type: SUBMIT_RECEIPT,
  payload: details,
});

export const setPdfViewer = (details) => ({
  type: SET_OPEN_PDF_VIWER,
  payload: details,
});

export const setSubmitting = (details) => ({
  type: SET_SUBMITTING,
  payload: details,
});

export const setSubmitedId = (details) => ({
  type: SET_SUBMITTED_ID,
  payload: details,
});
