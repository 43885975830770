import { i18n } from '../main/i18n/i18n';

/**
 * A helper to translate and set the first error message to Formik error object on validation.
 * @param {object} errorObject Formik error object.
 * @param {string} propertyName The form field name (corresponding to the form field).
 * @param  {...any} validationInformation An array with
 * [(Validation), (Error message key), (Optional: Error param object)] data.
 */
const addErrorIfFailed = (errorObject, propertyName, ...validationInformation) => {
  const firstFailedValidation = validationInformation.find(v => !v[0]);
  if (firstFailedValidation) {
    // Intentional property assignment to make the function signature easy to use.
    // eslint-disable-next-line no-param-reassign
    errorObject[propertyName] = i18n.t(firstFailedValidation[1], firstFailedValidation[2]);
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  addErrorIfFailed,
};
