import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const PrivateRoute = ({ isLoggedIn, children }) => {
  if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  return children;
};

PrivateRoute.propTypes = {
  isLoggedIn: PropTypes.bool,
  children: PropTypes.any,
};

const mapStateToProps = (state, parentProps) => ({
  isLoggedIn: !!state.user.token,
  children: parentProps.children,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
