import { takeEvery, put, call, select } from 'redux-saga/effects';
import apiService from '../../tools/apiService';
import {
  INIT,
  SIGN_IN,
  CLEAR_SIGN_IN,
  initState,
  setIsLoading,
  setLoginResponse,
} from './loginActions';
import { setLoginUser } from '../../user/userActions';

function* userSignIn(action) {
  yield put(setIsLoading(true));
  yield put(setLoginResponse(''));
  try {
    const { email, password } = action.payload;
    const apiValue = {
      email,
      password,
    };
    const response = yield call(apiService.post, '/login', apiValue);
    if (response.success) {
      const userDetail = {
        email: response.user.email,
        name: response.user.name,
        token: response.authorisation.token,
      };
      localStorage.setItem('email', JSON.stringify(userDetail.email));
      localStorage.setItem('name', JSON.stringify(userDetail.name));
      localStorage.setItem('token', JSON.stringify(userDetail.token));
      yield put(setLoginUser(userDetail));
    } else {
      yield put(setLoginResponse('Invalid E-mail or Password'));
    }
  } catch (error) {
    yield put(setLoginResponse(error.message));
  }
  yield put(setIsLoading(false));
}

function* userSignOut(action) {
  const { token } = yield select((state) => state.user);
  try {
    yield call(apiService.postWithToken, '/logout', token);
    const userDetail = {
      email: '',
      name: '',
      token: '',
    };
    localStorage.clear();
    yield put(setLoginUser(userDetail));
  } catch (error) {
    // yield put(loginFailure(error.message));
  }
}

function* onPageLoad() {
  yield call(initState());
}

export default function* loginSaga() {
  yield takeEvery(INIT, onPageLoad);
  yield takeEvery(SIGN_IN, userSignIn);
  yield takeEvery(CLEAR_SIGN_IN, userSignOut);
}
