import { takeEvery, put, call, select } from 'redux-saga/effects';
import apiService from '../tools/apiService';
import { mapChangePasswordToApi } from './userSettingSelector';

import {
  INIT_STATE,
  SUBMIT_CHANGE_PASSWORD,
  setChangePasswordResponse,
  setIsLoading,
} from './userSettingActions';

// eslint-disable-next-line require-yield
function* submitReceipt({ payload }) {
  yield put(setIsLoading(true));
  const { token } = yield select((state) => state.user);
  try {
    const apiValue = mapChangePasswordToApi(payload);
    const response = yield call(
      apiService.postWithToken,
      '/changePassword',
      token,
      apiValue
    );
    if (response) {
      if (response.success) {
        yield put(
          setChangePasswordResponse({
            success: true,
            message: response.message,
          })
        );
      } else {
        yield put(
          setChangePasswordResponse({
            success: false,
            message: response.message,
          })
        );
      }
    }
  } catch (error) {
    // yield put(loginFailure(error.message));
  }
  yield put(setIsLoading(false));
}

function* onPageLoad() {
  // try {
  // Something here
  // } catch (error) {
  //   console.error(error);
  // }
}

export default function* userSettingSaga() {
  yield takeEvery(INIT_STATE, onPageLoad);
  yield takeEvery(SUBMIT_CHANGE_PASSWORD, submitReceipt);
}
