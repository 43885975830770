import {
  INIT_STATE,
  SET_RECEIPT_LIST,
  SET_IS_LOADING,
  SET_TOTAL,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_QUERY,
} from "./managementPageActions";

const initialState = {
  receiptList: [],
  isLoading: false,
  page: 1,
  pageSize: 15,
  total: 0,
  query: {
    name: "",
    receiptNo: "",
    month: "",
  },
};

const managementPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_STATE:
      return initialState;
    case SET_RECEIPT_LIST:
      return {
        ...state,
        receiptList: action.payload,
      };
    case SET_TOTAL:
      return {
        ...state,
        total: action.payload,
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };
    case SET_QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

export default managementPageReducer;
