import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AppContainer from '../../layouts/appContainer';
import { useNavigate } from 'react-router-dom';
import { i18n } from '../../main/i18n/i18n';
import { Spin } from 'antd';
import { signIn } from './loginActions';

import { Form, Input, Alert, Space } from 'antd';

const LoginPage = (props) => {
  const { onSignIn, token, isLoading, loginResponse } = props;
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate('/generateReceipt');
    }
  }, [token, navigate]);

  const onFinish = (values) => {
    onSignIn(values);
  };

  return (
    <AppContainer>
      <Form
        name="login"
        initialValues={{ email: '', password: '' }}
        onFinish={onFinish}
        layout="vertical"
      >
        <div className="login-div">
          <div className="login-blur-area">
            <div className="form-area">
              <h1 className="mb-4">{i18n.t('word.login')}</h1>
              <div className="mt-2">
                <Form.Item
                  label={<label style={{ color: 'azure' }}>E-mail</label>}
                  name="email"
                  rules={[
                    { required: true, message: 'Please input your email!' },
                  ]}
                >
                  <Input disabled={isLoading} />
                </Form.Item>
              </div>
              <div className="mt-2">
                <Form.Item
                  label={<label style={{ color: 'azure' }}>Password</label>}
                  name="password"
                  rules={[
                    { required: true, message: 'Please input your password!' },
                  ]}
                  disabled={isLoading}
                >
                  <Input.Password disabled={isLoading} />
                </Form.Item>
              </div>
              <div className="mt-4 d-flex justify-content-center">
                <button
                  type="submit"
                  className="login-btn"
                  disabled={isLoading}
                >
                  {isLoading ? <Spin /> : `Login`}
                </button>
              </div>
              {loginResponse && (
                <div className="mt-5">
                  <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert message={loginResponse} type="error" showIcon />
                  </Space>
                </div>
              )}
              {/* <div className="register mt-3">
                    <p>
                      Don't have a account? <Link to="/register">Register</Link>
                    </p>
                  </div> */}
            </div>
          </div>
        </div>
      </Form>
    </AppContainer>
  );
};

LoginPage.propTypes = {
  token: PropTypes.string,
  isLoading: PropTypes.bool,
  loginResponse: PropTypes.string,

  onSignIn: PropTypes.func,
};

const mapStateToProps = (state) => ({
  token: state.user.token,
  isLoading: state.login.isLoading,
  loginResponse: state.login.loginResponse,
});

const mapDispatchToProps = (dispatch) => ({
  onSignIn: (values) => dispatch(signIn(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
