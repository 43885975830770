/* eslint-disable no-unused-vars */
import axios from "axios";

// const prodBaseUrl = "http://localhost:9001/api";
// const prodBaseUrl = 'http://1.9.76.62:9000/api';
const prodBaseUrl = "https://api.swimandjoysports.com/api";
// const prodBaseUrl = "https://api.littlecafe.xyz/api";

const apiService = {
  baseUrl: prodBaseUrl,
  get: async (endpoint) => {
    const url = `${apiService.baseUrl}${endpoint}`;
    const response = await fetch(url);
    const data = await response.json();
    return data;
  },

  getWithToken: async (endpoint, token) => {
    const url = `${apiService.baseUrl}${endpoint}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const response = await fetch(url, {
      method: "GET",
      headers,
    });
    const data = await response.json();
    return data;
  },

  getResourceWithToken: async (endpoint, token) => {
    const url = `${apiService.baseUrl}${endpoint}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await axios.get(url, {
        headers,
        responseType: "blob", // Set the response type to 'blob' to receive the PDF file as a Blob object
      });

      // Return the PDF file data as a Blob object
      return {
        data: response.data,
        success: true,
      };
    } catch (error) {
      return {
        errorMsg: error,
        success: false,
      };
    }
  },

  post: async (endpoint, body) => {
    const url = `${apiService.baseUrl}${endpoint}`;
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
  },

  postWithToken: async (endpoint, token, body) => {
    const url = `${apiService.baseUrl}${endpoint}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const response = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
  },
};

export default apiService;
