import { SET_MENU_COLLAP } from './appAction';

const initialState = {
  menu: true,
};

const contextReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MENU_COLLAP:
      return {
        ...state,
        menu: action.payload,
      };
    default:
      return state;
  }
};

export default contextReducer;
