import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from './privateRoute';

import LoginPage from '../auth/login/loginPage';
import HomePage from '../home/homePage';
import ManagementPage from '../receiptManagement/managementPage';
import ChangePasswordPage from '../userSettings/changePassword';
import PagenotFound from '../layouts/pageNotFound';

function RoutePath() {
  return (
    <Routes>
      <Route exact path="/" element={<LoginPage />} />
      <Route
        exact
        path="/generateReceipt"
        element={
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/manageReceipt"
        element={
          <PrivateRoute>
            <ManagementPage />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/changePassword"
        element={
          <PrivateRoute>
            <ChangePasswordPage />
          </PrivateRoute>
        }
      />
      <Route exact path="/notFound" element={<PagenotFound />} />
      {/* <Route path="*" element={<Navigate to="/notFound" replace />} /> */}
    </Routes>
  );
}

export default RoutePath;
