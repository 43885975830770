import { combineReducers } from 'redux';
import contextReducer from '../context/appReducer';
import loginReducer from '../auth/login/loginReducer';
import homeReducer from '../home/homeReducer';
import userReducer from '../user/userReducer';
import managementReducer from '../receiptManagement/managementPageReducer';
import userSettingReducer from '../userSettings/userSettingReducer';

export default combineReducers({
  context: contextReducer,
  home: homeReducer,
  management: managementReducer,
  login: loginReducer,
  user: userReducer,
  userSetting: userSettingReducer,
});
