import { takeEvery, put, call, select } from "redux-saga/effects";
import apiService from "../tools/apiService";

import {
  INIT_STATE,
  SUBMIT_EDIT_RECEIPT,
  SUBMIT_DELETE_RECEIPT,
  GET_RECEIPT_LIST,
  setReceiptList,
  setIsLoading,
  setTotal,
} from "./managementPageActions";
import {
  mapReceiptListFromApi,
  mapReceiptToApi,
} from "./managementPageSelector";

const convertQuery = (query) => {
  const queryArray = Object.keys(query).map((key) => {
    const keyValue = query[key];
    return `&${key}=${keyValue}`;
  });

  return queryArray.join("");
};

function* getReceipts() {
  yield put(setIsLoading(true));
  const { token } = yield select((state) => state.user);
  const { page, pageSize, query } = yield select((state) => state.management);

  const response = yield call(
    apiService.getWithToken,
    `/get-all-receipt?page=${page}&per_page=${pageSize}${convertQuery(query)}`,
    token
  );
  if (response.success) {
    const receiptList = mapReceiptListFromApi(response.receipts);
    yield put(setReceiptList(receiptList));
    yield put(setTotal(response.total));
  } else {
    yield put(setReceiptList([]));
  }
  yield put(setIsLoading(false));
}

function* submitEditReceipt({ payload }) {
  yield put(setIsLoading(true));
  const { token } = yield select((state) => state.user);
  try {
    const apiValue = mapReceiptToApi(payload);
    const response = yield call(
      apiService.postWithToken,
      "/editReceipt",
      token,
      apiValue
    );
    if (response.success) {
      yield call(getReceipts);
    }
  } catch (error) {
    // yield put(loginFailure(error.message));
  }
  yield put(setIsLoading(false));
}

function* submitDeleteReceipt({ payload }) {
  yield put(setIsLoading(true));
  const { token } = yield select((state) => state.user);
  try {
    const apiValue = { id: payload.id };
    const response = yield call(
      apiService.postWithToken,
      "/deleteReceipt",
      token,
      apiValue
    );
    if (response.success) {
      yield call(getReceipts);
    }
  } catch (error) {
    // yield put(loginFailure(error.message));
  }
  yield put(setIsLoading(false));
}

function* onPageLoad() {
  try {
    yield call(getReceipts);
  } catch (error) {
    console.error(error);
  }
}

export default function* managementPageSaga() {
  yield takeEvery(INIT_STATE, onPageLoad);
  yield takeEvery(SUBMIT_EDIT_RECEIPT, submitEditReceipt);
  yield takeEvery(SUBMIT_DELETE_RECEIPT, submitDeleteReceipt);
  yield takeEvery(GET_RECEIPT_LIST, getReceipts);
}
