/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { initState } from './userSettingActions';
import { Button, Form, Input, Alert, Space } from 'antd';
import {
  submitChangePassword,
  setChangePasswordResponse,
} from './userSettingActions';
import AppContainer from '../layouts/appContainer';

const ChangePassword = (props) => {
  const {
    isLoading,
    changePasswordResponse,
    onInitState,
    onSubmitChangePassword,
    onSetChangePasswordResponse,
  } = props;

  useEffect(() => {
    onInitState();
  }, []);

  const onFinish = (values) => {
    onSubmitChangePassword(values);
  };

  const onFinishFailed = (errorInfo) => {
    onSetChangePasswordResponse(errorInfo);
  };
  return (
    <AppContainer menubar>
      <div className="container-fluid mt-2" style={{ overflow: 'hidden' }}>
        <h4 className="page-title ms-2">Change Password</h4>
        <div
          className="p-3 me-2"
          style={{ backgroundColor: 'white', borderRadius: 20 }}
        >
          <Form
            disabled={isLoading}
            name="basic"
            labelCol={{
              span: 8,
            }}
            style={{
              maxWidth: 600,
              marginTop: 15,
            }}
            initialValues={{
              oldPassword: '',
              newPassword: '',
              newConfirmPassword: '',
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Old Password"
              name="oldPassword"
              rules={[
                {
                  required: true,
                  message: 'Please input your old password!',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: 'Please input your new password!',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="New Confirm Password"
              name="newConfirmPassword"
              dependencies={['newPassword']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        'The new password that you entered do not match!'
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
          {Object.keys(changePasswordResponse).length > 0 && (
            <div className="mt-5">
              {changePasswordResponse.success ? (
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Alert
                    message={changePasswordResponse.message}
                    type="success"
                    showIcon
                  />
                </Space>
              ) : (
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Alert
                    message={changePasswordResponse.message}
                    type="error"
                    showIcon
                  />
                </Space>
              )}
            </div>
          )}
        </div>
      </div>
    </AppContainer>
  );
};

ChangePassword.propTypes = {
  token: PropTypes.string,
  changePasswordResponse: PropTypes.object,
  isLoading: PropTypes.bool,

  onInitState: PropTypes.func,
  onSubmitChangePassword: PropTypes.func,
  onSetChangePasswordResponse: PropTypes.func,
};

const mapStateToProps = (state) => ({
  token: state.user.token,
  changePasswordResponse: state.userSetting.changePasswordResponse,
  isLoading: state.userSetting.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  onInitState: () => dispatch(initState()),
  onSubmitChangePassword: (details) => dispatch(submitChangePassword(details)),
  onSetChangePasswordResponse: (details) =>
    dispatch(setChangePasswordResponse(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
