const SETTING = 'USER_SETTING';

export const INIT = `${SETTING}/INIT`;
export const INIT_STATE = `${SETTING}/INIT_STATE`;
export const SET_IS_LOADING = `${SETTING}/SET_IS_LOADING`;
export const SUBMIT_CHANGE_PASSWORD = `${SETTING}/SUBMIT_CHANGE_PASSWORD`;
export const SET_CHANGE_PASSWORD_RESPONSE = `${SETTING}/SET_CHANGE_PASSWORD_RESPONSE`;

export const init = () => ({
  type: INIT,
});

export const initState = () => ({
  type: INIT_STATE,
});

export const submitChangePassword = (details) => ({
  type: SUBMIT_CHANGE_PASSWORD,
  payload: details,
});

export const setChangePasswordResponse = (details) => ({
  type: SET_CHANGE_PASSWORD_RESPONSE,
  payload: details,
});

export const setIsLoading = (loading) => ({
  type: SET_IS_LOADING,
  payload: loading,
});
