/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import apiService from '../tools/apiService';
import { setPdfViewer, setSubmitedId, setSubmitting } from './homeActions';
import { Modal, Button, Spin } from 'antd';
import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const ManagementPage = (props) => {
  const {
    token,
    submittedId,
    openPdfViewer,
    isSubmiting,
    onSetSubmittedId,
    onSetPdfViewer,
    onSetSubmitting,
  } = props;

  const isMobile = window.innerWidth <= 768;
  const [pdfBlobUrl, setPdfBlobUrl] = useState('');
  const [pageNumber] = useState(1);

  const extractBlobContent = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const content = reader.result;
        resolve(content);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsText(blob);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await apiService.getResourceWithToken(
        `/getReceipt/${submittedId}`,
        token
      );
      extractBlobContent(response.data)
        .then((content) => {
          try {
            // eslint-disable-next-line no-unused-vars
            const blobContent = JSON.parse(content);
          } catch (e) {
            const objectURL = URL.createObjectURL(response.data);
            setPdfBlobUrl(objectURL);
            onSetSubmitting(false);
          }
        })
        .catch((error) => {
          console.error(error); // Handle any error that occurs during extraction
        });
    };

    fetchData();
  }, [submittedId, openPdfViewer]);

  function downloadFileFile(objectURL, filename) {
    // Create a temporary link element
    const link = document.createElement('a');
    link.href = objectURL;
    link.download = filename;

    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up resources
    document.body.removeChild(link);
  }

  return (
    <Modal
      title="Receipt Viewer"
      open={openPdfViewer}
      onCancel={() => {
        onSetPdfViewer(false);
        setPdfBlobUrl('');
        onSetSubmittedId(0);
      }}
      centered
      zIndex={999}
      width={1000}
      style={{ height: 'auto', padding: 15 }}
      maskClosable={false}
      footer={[
        <Button
          key="submit"
          type="primary"
          disabled={pdfBlobUrl === ''}
          onClick={() => downloadFileFile(pdfBlobUrl, `receipt`)}
        >
          Download
        </Button>,
        <Button
          key="back"
          onClick={() => {
            onSetPdfViewer(false);
            setPdfBlobUrl('');
            onSetSubmittedId(0);
          }}
        >
          Close
        </Button>,
      ]}
    >
      {isSubmiting ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spin size="large">
            <div className="content" />
          </Spin>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          <Document file={pdfBlobUrl}>
            <Page pageNumber={pageNumber} scale={isMobile ? 0.6 : 1.5} />
          </Document>
        </div>
      )}
    </Modal>
  );
};

ManagementPage.propTypes = {
  submittedId: PropTypes.number,
  openPdfViewer: PropTypes.bool,
  isSubmiting: PropTypes.bool,
  token: PropTypes.string,

  onSetSubmittedId: PropTypes.func,
  onSetSubmitting: PropTypes.func,
  onSetPdfViewer: PropTypes.func,
};

const mapStateToProps = (state) => ({
  submittedId: state.home.submittedId,
  openPdfViewer: state.home.openPdfViewer,
  isSubmiting: state.home.isSubmiting,
  token: state.user.token,
});

const mapDispatchToProps = (dispatch) => ({
  onSetPdfViewer: (values) => dispatch(setPdfViewer(values)),
  onSetSubmittedId: (values) => dispatch(setSubmitedId(values)),
  onSetSubmitting: (values) => dispatch(setSubmitting(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManagementPage);
