import React from 'react';
import RoutePath from './main/routes';
import I18N from './main/i18n/i18n';
import './styles/App.scss';

require('jquery/dist/jquery.slim');
require('bootstrap/dist/js/bootstrap');

function App() {
  return (
    <>
      <div className="App">
        <I18N>
          <RoutePath />
        </I18N>
      </div>
    </>
  );
}

export default App;
