import { SET_LOGIN_USER, SET_UPDATED_USER_INFO } from './userActions';

const getDefaultState = () => ({
  email: '',
  name: '',
  userType: 0,
  token: '',
});

const reducer = (state, action) => {
  if (state === undefined) {
    return getDefaultState();
  }

  switch (action.type) {
    case SET_LOGIN_USER:
      return {
        ...state,
        email: action.email,
        name: action.name,
        userType: action.userType,
        token: action.token,
      };

    case SET_UPDATED_USER_INFO:
      return {
        ...state,
        name: action.name,
      };

    default:
      return state;
  }
};

export default reducer;
