/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AppContainer from "../layouts/appContainer";
import apiService from "../tools/apiService";
import {
  initState,
  submitDeleteReceipt,
  getReceiptList,
  setPage,
  setPageSize,
  setQuery,
} from "./managementPageActions";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import {
  Table,
  Modal,
  Button,
  Spin,
  Input,
  Space,
  ConfigProvider,
  Popconfirm,
  Tooltip,
  Select,
  Dropdown,
  Pagination,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { MonthListArray } from "../main/enums";

import EditReceiptModal from "./editReceiptModal";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const ManagementPage = (props) => {
  const {
    token,
    page,
    total,
    query,
    receiptList,
    isLoading,
    onInitState,
    onDeleteReceipt,
    onGetReceipt,
    onSetPageSize,
    onSetPage,
    onSetQuery,
  } = props;

  useEffect(() => {
    onInitState();
  }, []);

  const isMobile = window.innerWidth <= 768;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedReceipt, setSelectedReceipt] = useState({});
  const [dropDownReceipt, setDropDownReceipt] = useState({});
  const [pdfBlobUrl, setPdfBlobUrl] = useState("");
  const [filterNameId, setFilterNameId] = useState("");
  const [filterMonth, setFilterMonth] = useState([]);
  const [pageNumber] = useState(1);

  useEffect(() => {
    if (isMobile) {
      onSetPageSize(5);
    } else {
      onSetPageSize(15);
    }
  }, []);

  useEffect(() => {
    onGetReceipt();
  }, [page]);

  const FilterByNameInput = (
    <Space size="middle" wrap>
      <Input
        addonBefore={<SearchOutlined />}
        placeholder="Search Customer Name, Receipt No"
        size="large"
        allowClear
        styles={{ width: "200px" }}
        value={query["name"]}
        onChange={(e) => {
          const currValue = e.target.value;
          onSetQuery({ ...query, name: currValue });
        }}
      />
      <Input
        addonBefore={<SearchOutlined />}
        placeholder="Search Receipt No"
        size="large"
        allowClear
        styles={{ width: "200px" }}
        value={query["receiptNo"]}
        onChange={(e) => {
          const currValue = e.target.value;
          console.log("QQQQQQQQQ", query);

          onSetQuery({ ...query, receiptNo: currValue });
        }}
      />
      <Select
        size="large"
        mode="multiple"
        placeholder="Select by month..."
        onChange={(value) => onSetQuery({ ...query, month: value.join(",") })}
        style={{ width: "auto", minWidth: 260 }}
        options={MonthListArray}
        allowClear
      />
      <button
        type="button"
        className="search-btn"
        disabled={isLoading}
        onClick={onGetReceipt}
      >
        Search
      </button>
    </Space>
  );

  const getDocumentPath = async (id) => {
    try {
      const response = await apiService.getResourceWithToken(
        `/getReceipt/${id}`,
        token
      );
      if (!response.success) {
        throw new Error("Failed to fetch PDF");
      }
      const objectURL = window.URL.createObjectURL(response.data);
      setPdfBlobUrl(objectURL);
    } catch (error) {
      console.error(error);
    }
  };

  function downloadFileFile(objectURL, filename) {
    // Create a temporary link element
    const link = document.createElement("a");
    link.href = objectURL;
    link.download = filename;

    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up resources
    document.body.removeChild(link);
  }

  const confirm = (receipt) => {
    onDeleteReceipt({ id: receipt.id });
  };

  const handleNextPage = (page) => {
    onSetPage(page);
  };

  const columns = [
    {
      title: "Receipt No",
      dataIndex: "receiptNo",
      key: "receiptNo",
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
    },
    {
      title: "Payment Months",
      dataIndex: "paymentForMonth",
      key: "paymentForMonth",
      filters: MonthListArray,
      filterSearch: true,
      onFilter: (value, record) => record.paymentForMonth.includes(value),
    },
    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      render: (_, record) => {
        return (
          <p style={{ textAlign: "left", alignItems: "center", margin: 0 }}>
            {record.paymentDate.split(" ")[0]}
          </p>
        );
      },
    },
    {
      title: "Amount (RM)",
      dataIndex: "totalAmount",
      key: "totalAmount",
      align: "left",
      render: (_, record) => (
        <p style={{ textAlign: "right", alignItems: "center", margin: 0 }}>
          {record.totalAmount.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}
        </p>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <div className="d-flex justify-content-center">
          <Tooltip title="View">
            <button
              type="button"
              className="no-decoration-btn"
              onClick={() => {
                setIsModalOpen(true);
                setSelectedReceipt(record);
                getDocumentPath(record.id);
              }}
            >
              <i className="fa-solid fa-eye"></i>
            </button>
          </Tooltip>
          <Tooltip title="Edit">
            <button
              type="button"
              className="no-decoration-btn ms-4"
              onClick={() => {
                setSelectedReceipt(record);
                setIsEditModalOpen(true);
              }}
            >
              <i className="fa-solid fa-file-pen"></i>
            </button>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Delete Receipt"
              description="Are you sure to delete this receipt?"
              onConfirm={() => confirm(record)}
              okText="Yes"
              cancelText="No"
            >
              <button type="button" className="no-decoration-btn ms-4">
                <i className="fa-solid fa-trash" style={{ color: "red" }}></i>
              </button>
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  const items = [
    {
      label: (
        <div className="d-flex flex-row justify-content-around">
          <button
            type="button"
            className="no-decoration-btn"
            onClick={() => {
              setIsModalOpen(true);
              setSelectedReceipt(dropDownReceipt);
              getDocumentPath(dropDownReceipt.id);
            }}
          >
            <i className="fa-solid fa-eye"></i>
          </button>
          <button
            type="button"
            className="no-decoration-btn ms-4"
            onClick={() => {
              setSelectedReceipt(dropDownReceipt);
              setIsEditModalOpen(true);
            }}
          >
            <i className="fa-solid fa-file-pen"></i>
          </button>
          <Popconfirm
            title="Delete Receipt"
            description="Are you sure to delete this receipt?"
            onConfirm={() => confirm(dropDownReceipt)}
            okText="Yes"
            cancelText="No"
          >
            <button type="button" className="no-decoration-btn ms-4">
              <i className="fa-solid fa-trash" style={{ color: "red" }}></i>
            </button>
          </Popconfirm>
        </div>
      ),
      key: "0",
    },
  ];

  const handleOpenDropDownMenu = (open, record) => {
    if (open) {
      setDropDownReceipt(record);
    } else {
      setDropDownReceipt({});
    }
  };

  const mobileColumns = [
    {
      key: "action",
      align: "left",
      render: (_, record) => {
        const originalDateTime = new Date(record.paymentDate);
        const dateOnlyString = originalDateTime.toISOString().split("T")[0];
        return (
          <Dropdown
            menu={{ items }}
            trigger={["click"]}
            onOpenChange={(menu) => handleOpenDropDownMenu(menu, record)}
          >
            <div className="d-flex flex-column">
              <div
                className="d-flex justify-content-between"
                style={{ fontWeight: 700, fontSize: 14 }}
              >
                <span>{record.receiptNo}</span>
                <span>RM {record.totalAmount}</span>
              </div>
              <div>
                <i className="fa-solid fa-user me-1"></i>
                {`${record.customerName} ${
                  record.membershipNo ? `(${record.membershipNo})` : ""
                }`}
              </div>
              <div>
                <i className="fa-solid fa-calendar-days me-1"></i>
                {dateOnlyString}
              </div>
              <div
                className="d-flex justify-content-between"
                style={{ color: "grey" }}
              >
                <span className="text-start">{record.paymentForMonth}</span>
                <span className="text-end">{record.paymentMethod}</span>
              </div>
            </div>
          </Dropdown>
        );
      },
    },
  ];

  const newDataSource = useMemo(() => {
    const filterNameIdList =
      filterNameId !== ""
        ? receiptList.filter(
            (r) =>
              r.customerName
                .toLowerCase()
                .includes(filterNameId.toLowerCase()) ||
              r.receiptNo.toLowerCase().includes(filterNameId.toLowerCase())
          )
        : receiptList;

    const filterMonthList =
      filterMonth.length !== 0
        ? filterNameIdList.filter((r) =>
            r.paymentForMonth
              .toLowerCase()
              .includes(filterMonth.join(", ").toLowerCase())
          )
        : filterNameIdList;
    return filterMonthList;
  }, [filterNameId, filterMonth, receiptList]);

  return (
    <AppContainer menubar>
      <Modal
        title="Receipt Viewer"
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          setPdfBlobUrl("");
          setSelectedReceipt({});
        }}
        centered
        width={1000}
        zIndex={999}
        style={{ height: "auto", padding: 15 }}
        maskClosable={false}
        footer={[
          <Button
            key="submit"
            type="primary"
            disabled={pdfBlobUrl === ""}
            onClick={() =>
              downloadFileFile(
                pdfBlobUrl,
                `${selectedReceipt.customerName}_${selectedReceipt.receiptNo}`
              )
            }
          >
            Download
          </Button>,
          <Button
            key="back"
            onClick={() => {
              setIsModalOpen(false);
              setPdfBlobUrl("");
              setSelectedReceipt({});
            }}
          >
            Close
          </Button>,
        ]}
      >
        {pdfBlobUrl === "" ? (
          <div
            className="d-flex justify-content-center align-items-center"
            // style={{ width: '920px', height: '600px' }}
          >
            <Spin size="large">
              <div className="content" />
            </Spin>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <Document file={pdfBlobUrl}>
              <Page pageNumber={pageNumber} scale={isMobile ? 0.6 : 1.5} />
            </Document>
          </div>
        )}
      </Modal>
      <EditReceiptModal
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
          setSelectedReceipt({});
        }}
        selectedReceipt={selectedReceipt}
      />
      <div className={`container-fluid m-0 ${isMobile ? "pe-4" : ""}`}>
        <div className="p-3">
          <h1 className="page-title">SNJ Receipt Management</h1>
        </div>
        <div className="p-3">{FilterByNameInput}</div>
        <div
          className={`ps-3 pe-3 ${
            !isLoading ? "" : "d-flex justify-content-center align-items-center"
          }`}
          style={{ height: "90vh" }}
        >
          {!isLoading ? (
            <ConfigProvider
              theme={{
                token: {
                  lineWidth: 1.5,
                  colorFillQuaternary: "#eeeeee",
                  colorBorderSecondary: "#d3d3d3",
                },
              }}
            >
              <div className="d-flex flex-column">
                <Table
                  columns={isMobile ? mobileColumns : columns}
                  bordered
                  dataSource={newDataSource}
                  rowKey={(record) => record.id}
                  showHeader={!isMobile}
                  pagination={false}
                />
                <div className="d-flex justify-content-end mt-4">
                  <Pagination
                    defaultCurrent={page}
                    defaultPageSize={isMobile ? 5 : 15}
                    total={total}
                    showSizeChanger={false}
                    onChange={(e) => handleNextPage(e)}
                    simple={isMobile}
                  />
                </div>
              </div>
            </ConfigProvider>
          ) : (
            <Spin size="large">
              <div className="content" />
            </Spin>
          )}
        </div>
      </div>
    </AppContainer>
  );
};

ManagementPage.propTypes = {
  receiptList: PropTypes.array,
  isLoading: PropTypes.bool,
  token: PropTypes.string,
  page: PropTypes.number,
  total: PropTypes.number,
  query: PropTypes.object,

  onInitState: PropTypes.func,
  onDeleteReceipt: PropTypes.func,
  onSetPage: PropTypes.func,
  onSetPageSize: PropTypes.func,
};

const mapStateToProps = (state) => ({
  receiptList: state.management.receiptList,
  isLoading: state.management.isLoading,
  page: state.management.page,
  query: state.management.query,
  total: state.management.total,
  token: state.user.token,
});

const mapDispatchToProps = (dispatch) => ({
  onInitState: () => dispatch(initState()),
  onDeleteReceipt: (value) => dispatch(submitDeleteReceipt(value)),
  onSetPage: (value) => dispatch(setPage(value)),
  onGetReceipt: () => dispatch(getReceiptList()),
  onSetPageSize: (value) => dispatch(setPageSize(value)),
  onSetQuery: (value) => dispatch(setQuery(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManagementPage);
