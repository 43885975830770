import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/react-pdf/dist/cjs/Page/AnnotationLayer.css';
import '../node_modules/react-pdf/dist/cjs/Page/TextLayer.css';
import store from './main/store';
import { setLoginUser } from '../src/user/userActions';
import App from './App';
import jwtDecode from 'jwt-decode';

const checkJwtToken = (token) => {
  const decodedToken = jwtDecode(token);
  const currentTime = Date.now() / 1000; // convert to seconds
  return decodedToken.exp < currentTime;
};

const userDetails = {
  name: JSON.parse(localStorage.getItem('name')),
  email: JSON.parse(localStorage.getItem('email')),
  token: JSON.parse(localStorage.getItem('token')),
};
const hasEveryValue = Object.values(userDetails).every((value) =>
  Boolean(value)
);
if (hasEveryValue) {
  const expiredToken = checkJwtToken(userDetails.token);
  if (!expiredToken) {
    store.dispatch(setLoginUser(userDetails));
  } else {
    const userDetail = {
      email: '',
      name: '',
      token: '',
    };
    localStorage.clear();
    store.dispatch(setLoginUser(userDetail));
  }
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
);

reportWebVitals();
