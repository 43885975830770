import {
  INIT_STATE,
  SET_IS_LOADING,
  SET_CHANGE_PASSWORD_RESPONSE,
} from './userSettingActions';

const initialState = {
  isLoading: false,
  changePasswordResponse: {},
};

const userSettingReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_STATE:
      return initialState;
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_CHANGE_PASSWORD_RESPONSE:
      return {
        ...state,
        changePasswordResponse: action.payload,
      };
    default:
      return state;
  }
};

export default userSettingReducer;
