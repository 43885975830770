const LOGIN = 'LOGIN';

export const INIT = `${LOGIN}/INIT`;
export const INIT_STATE = `${LOGIN}/INIT_STATE`;
export const SET_IS_LOADING = `${LOGIN}/SET_IS_LOADING`;
export const SIGN_IN = `${LOGIN}/SIGN_IN`;
export const CLEAR_SIGN_IN = `${LOGIN}/CLEAR_SIGN_IN`;
export const SET_LOGIN_RESPONSE = `${LOGIN}/SET_LOGIN_RESPONSE`;

export const init = () => ({
  type: INIT,
});

export const initState = () => ({
  type: INIT_STATE,
});

export const signIn = (details) => ({
  type: SIGN_IN,
  payload: details,
});

export const clarSignIn = () => ({
  type: CLEAR_SIGN_IN,
});

export const setIsLoading = (details) => ({
  type: SET_IS_LOADING,
  payload: details,
});

export const setLoginResponse = (details) => ({
  type: SET_LOGIN_RESPONSE,
  payload: details,
});
