import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import logo from '../assets/logo.png';
import { clarSignIn } from '../auth/login/loginActions';
import { useNavigate } from 'react-router-dom';
import { Menu, ConfigProvider } from 'antd';
import { setMenuCollap } from '../context/appAction';

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const MenuBar = ({ menu, onSignOut, onSetMenuCollapsed }) => {
  const navigate = useNavigate();

  const handleOnClick = (values) => {
    if (values.key === 'signOut') {
      onSignOut();
    } else {
      navigate(`/${values.key}`);
    }
  };

  const items = [
    getItem(
      'Generate Receipt',
      'generateReceipt',
      <i className={`fa-solid fa-receipt fa-lg`} style={{ color: 'azure' }}></i>
    ),
    getItem(
      'Manage Receipt',
      'manageReceipt',
      <i
        className={`fa-solid fa-table-list fa-lg`}
        style={{ color: 'azure' }}
      ></i>
    ),
    getItem(
      'Setting',
      '',
      <i className={`fa-solid fa-gear fa-lg`} style={{ color: 'azure' }}></i>,
      [
        getItem(
          'Change Password',
          'changePassword',
          <i className={`fa-solid fa-key`} style={{ color: 'azure' }}></i>
        ),
      ]
    ),
    getItem(
      'Sign Out',
      'signOut',
      <i className="fa-solid fa-power-off fa-lg" style={{ color: 'azure' }}></i>
    ),
  ];

  return (
    <div
      className="sideNav d-flex flex-column"
      style={{ width: menu ? 80 : 256 }}
    >
      <div className="sideNavHeader d-flex justify-content-center mt-3">
        <img src={logo} alt="logo" className="sideNavLogo" />
      </div>
      <div className="sideNavList mt-3 mb-3">
        <button
          type="button"
          className="no-decoration-btn"
          onClick={() => onSetMenuCollapsed(!menu)}
        >
          <i className="fa-solid fa-bars" style={{ color: 'azure' }}></i>
        </button>
        <ConfigProvider
          theme={{
            token: {
              colorBgBase: '#232323',
              colorTextBase: menu ? 'black' : 'azure',
              colorText: 'azure',
            },
          }}
        >
          <Menu
            mode="inline"
            items={items}
            onClick={handleOnClick}
            inlineCollapsed={menu}
          />
        </ConfigProvider>
      </div>
    </div>
  );
};

MenuBar.propTypes = {
  menu: PropTypes.bool,

  onSignOut: PropTypes.func,
  onSetMenuCollapsed: PropTypes.func,
};

const mapStateToProps = (state) => ({
  menu: state.context.menu,
});

const mapDispatchToProps = (dispatch) => ({
  onSignOut: () => dispatch(clarSignIn()),
  onSetMenuCollapsed: (menu) => dispatch(setMenuCollap(menu)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuBar);
