import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { i18n } from '../main/i18n/i18n';
import { useNavigate } from 'react-router-dom';
import { clarSignIn } from '../auth/login/loginActions';
import { Button, Result } from 'antd';

const NotFoundPage = ({ token, onSignOut }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate('/');
    }
  }, [token, navigate]);

  return (
    <div
      className="d-flex flex-column align-self-center"
      style={{ height: '100vh' }}
    >
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={[
          <Button type="primary" onClick={() => navigate('/generateReceipt')}>
            {i18n.t('word.backToHome')}
          </Button>,
          <Button onClick={() => onSignOut()}>{i18n.t('word.logout')}</Button>,
        ]}
      />
    </div>
  );
};

NotFoundPage.propTypes = {
  token: PropTypes.string,
  onSignOut: PropTypes.func,
};

const mapStateToProps = (state) => ({
  token: state.user.token,
});

const mapDispatchToProps = (dispatch) => ({
  onSignOut: () => dispatch(clarSignIn()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotFoundPage);
