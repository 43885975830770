export const PaymentMethod = {
  OnlineBanking: {
    label: "Online Banking",
    value: "Online Banking",
  },
  Cash: {
    label: "Cash",
    value: "Cash",
  },
};

export const MonthList = {
  January: {
    value: "January",
    label: "January",
  },
  February: {
    value: "February",
    label: "February",
  },
  March: {
    value: "March",
    label: "March",
  },
  April: {
    value: "April",
    label: "April",
  },
  May: {
    value: "May",
    label: "May",
  },
  June: {
    value: "June",
    label: "June",
  },
  July: {
    value: "July",
    label: "July",
  },
  August: {
    value: "August",
    label: "August",
  },
  September: {
    value: "September",
    label: "September",
  },
  October: {
    value: "October",
    label: "October",
  },
  November: {
    value: "November",
    label: "November",
  },
  December: {
    value: "December",
    label: "December",
  },
};

export const MonthListArray = [
  {
    text: 'January',
    value: 'January',
  },
  {
    text: 'February',
    value: 'February',
  },
  {
    text: 'March',
    value: 'March',
  },
  {
    text: 'April',
    value: 'April',
  },
  {
    text: 'May',
    value: 'May',
  },
  {
    text: 'June',
    value: 'June',
  },
  {
    text: 'July',
    value: 'July',
  },
  {
    text: 'August',
    value: 'August',
  },
  {
    text: 'September',
    value: 'September',
  },
  {
    text: 'October',
    value: 'October',
  },
  {
    text: 'November',
    value: 'November',
  },
  {
    text: 'December',
    value: 'December',
  },
]

export const PaymentType = {
  Registration: {
    label: "Registration",
    value: "Registration",
  },
  Deposit: {
    label: "Deposit",
    value: "Deposit",
  },
  Fee: {
    label: "Fee",
    value: "Fee",
  },
};
