import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import AppFooter from './appFooter';
// import Navbar from '../components/navBar';
import Menubar from "../components/menuBar";

const AppPage = ({ children, navbar, menubar }) => {
  return (
    <div className="app-page-container" onContextMenu={null}>
      <div className="d-flex flex-row">
        {menubar && <Menubar />}
        <div className="app-page-content p-0">{children}</div>
      </div>
      {/* <AppFooter /> */}
    </div>
  );
};

AppPage.propTypes = {
  children: PropTypes.any,
  navbar: PropTypes.bool,
  menubar: PropTypes.bool,
};

// const mapStateToProps = state => ({
//   selectedCompanyName: state.persist.selectedCompanyName,
// });

export default connect(null, null)(AppPage);
