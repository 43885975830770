import { INIT_STATE, SET_IS_LOADING, SET_LOGIN_RESPONSE } from './loginActions';

const initialState = {
  count: 0,
  isLoading: false,
  loginResponse: '',
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_STATE:
      return initialState;
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_LOGIN_RESPONSE:
      return {
        ...state,
        loginResponse: action.payload,
      };
    default:
      return state;
  }
};

export default homeReducer;
