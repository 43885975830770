import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DatePickerComponent = ({
  field,
  form,
  label,
  placeholder,
  divClassName,
  disabled,
}) => {
  function onChange(option) {
    form.setFieldValue(field.name, option ? option : null);
  }

  const getValue = () => {
    if (field.value) {
      return field.value;
    } else {
      return null;
    }
  };

  return (
    <div className={`${divClassName || ''}`}>
      {label && (
        <label className="formlabel" htmlFor={field.name}>
          {label}
        </label>
      )}
      <DatePicker
        selected={getValue()}
        onChange={onChange}
        placeholderText={placeholder}
        disabled={disabled}
      />
      {form.errors && form.errors[field.name] ? (
        <div className="error mt-1">{form.errors[field.name]}</div>
      ) : null}
    </div>
  );
};

export default DatePickerComponent;
