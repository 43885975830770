const USER = 'CONTEXT/USER';

export const SET_LOGIN_USER = `${USER}/SET_LOGIN_USER`;
export const SET_UPDATED_USER_INFO = `${USER}/SET_UPDATED_USER_INFO`;
export const SIGN_OUT = `${USER}/SIGN_OUT`;

export const setLoginUser = (info) => ({
  type: SET_LOGIN_USER,
  ...info,
});

export const setUpdatedUserInfo = (info) => ({
  type: SET_UPDATED_USER_INFO,
  ...info,
});

export const signOut = () => ({
  type: SIGN_OUT,
});
