import { all, fork, put } from 'redux-saga/effects';
import { setMenuCollap } from '../context/appAction';

import appSaga from '../context/appSaga';
import loginSage from '../auth/login/loginSaga';
import homeSaga from '../home/homeSaga';
import managementSaga from '../receiptManagement/managementPageSaga';
import userSettingSaga from '../userSettings/userSettingSaga';

export default function* root() {
  yield all([
    fork(appSaga),
    fork(homeSaga),
    fork(loginSage),
    fork(managementSaga),
    fork(userSettingSaga),
    put(setMenuCollap(true)),
  ]);
}
