import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import Select from 'react-select';

const FormikInput = (props) => {
  const { label, isMulti, options, placeholder, divClassName } = props;

  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props);

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option) => field.value.indexOf(option.value) >= 0)
        : options.find((option) => option.value === field.value);
    } else {
      return isMulti ? [] : '';
    }
  };

  return (
    <div className={`${divClassName || ''}`}>
      {label && (
        <label className="formlabel" htmlFor={field.name}>
          {label}
        </label>
      )}
      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        options={options}
        name={field.name}
        value={getValue() ? getValue() : ''}
        onChange={(option) => helpers.setValue(option.value)}
        // onBlur={field.onBlur}
        placeholder={placeholder}
      />
      {meta.error ? <div className="error mt-1">{meta.error}</div> : null}
    </div>
  );
};

FormikInput.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.string.isRequired,
  ]),
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onValueChange: PropTypes.func,
  labelClassName: PropTypes.string,
  divClassName: PropTypes.string,
};

export default FormikInput;
